.wp-block-swiper-carousel {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 1.5rem;
    padding-top: 20px;
    padding-bottom: 20px;

    @extend .card;

    .swiper-slide > div {
        height: 0;
        padding-bottom: 100%;
    }
}

.carousel {
    &__wrapper {
        max-width: 100%;
        overflow-x: hidden;
        margin: 0 10px;

        .swiper-slide {
            text-align: center;

            img {
                height: 250px;
            }
        }
    }
}

.swiper-pagination {
    &-bullet {
        background-color: darken($white, 20%);
        transition: background-color .5s;

        &-active {
            background-color: $white;
        }
    }
}