@media only screen and (min-width: 1200px) {
  .header {
    &--hide,
    &--shown {
      .header-nav {
        min-height: 80px;
      }
      .header-logo-link {
        transform: scale(0.59);
        transform-origin: top;
        margin-left: -74px;
        transition: transform 250ms ease-in-out;
      }
    }

    &--top {
      .header-nav {
        min-height: 160px;
      }
      .header-logo-link {
        transform: scale(1);
        transform-origin: top;
        margin-left: -115px;
      }
    }
  }
}
