.page-case-studies {
    a.media__featured--post {
        .card {
            margin-bottom: 20px !important;
        }
    }

    .category {
        .card--title,
        .btn__arrow {
            text-align: left;
        }
    }

    .media__featured--post .card-body {
        @media only screen and (max-width: 1145px) {
            flex-direction: column !important;

            .card--image {
                min-height: 250px !important;
            }
        }
    }
}
