.tagged-image {
    &__container {
        position: relative;
    }

    &__image {
        max-width: 100%;
    }

    &__pill {
        position: absolute;
        left: 10px;
        bottom: 10px;
        right: 10px;
        white-space: normal;
    }
}

.page-photo-gallery{
    .tagged-image__pill{
        display: none;
    }
}