.page-case-studies,
.page-news {
    @media only screen and (max-width: 991px) {
        .card-body {
            flex-direction: column !important;
            .card--image {
                min-height: 250px !important;
                max-height: 400px;
            }

            .card--content-container {
                padding-left: 0 !important;
            }
        }
        .media__featured--post:nth-child(2n) .card-img-container {
            order: 0;
        }
    }

    .category .card-body .card--image {
        min-height: 150px !important;
        @media only screen and (max-width: 575px) {
            min-height: 200px !important;
        }
    }
}
