//.search-icon {
//    background-color: transparent;
//    height: 50px;
//    width: 50px;
//    display: block;
//    padding: 0.5em;
//    margin: 0 0 0 1em;
//    position: relative;
//    cursor: pointer;
//    border: none;
//    z-index: 4;
//
//    &:focus {
//        outline: none;
//        box-shadow: none !important;
//        border: 0 solid !important;
//    }
//    @media #{$navBreak} {
//        display: none;
//    }
//
//    &__input {
//        width: 0;
//        padding: 0;
//        height: 20px;
//        transition: padding .3s, width .3s;
//        color: $text;
//        border: none;
//        background-color: transparent;
//    }
//}
//
//.circle {
//    position: absolute;
//    top: 10px;
//    display: block;
//    min-height: 20px;
//    min-width: 20px;
//    height: 20px;
//    background-color: transparent;
//    border-radius: 2rem;
//    border: 3px solid $white;
//    transition: transform 300ms ease-in-out, left 300ms ease-in-out, top 300ms ease-in-out, height 300ms;
//}
//
//.handle {
//    position: absolute;
//    background-color: $white;
//    top: 25px;
//    left: 28px;
//    width: 3px;
//    height: 12px;
//    display: block;
//    transform: rotate(-45deg);
//    float: right;
//    border-radius: 2px;
//    transition: transform 300ms ease-in-out, left 300ms ease-in-out, top 300ms ease-in-out;
//    &:after {
//        content: "";
//        background-color: $white;
//        width: 3px;
//        height: 12px;
//        display: block;
//        float: right;
//        border-radius: 2px;
//        z-index: -1;
//        transition: transform 300ms ease-in-out, left 300ms ease-in-out, top 300ms ease-in-out;
//    }
//}
//
//.open {
//    transition: transform 300ms ease-in-out, top 300ms ease-in-out, left 300ms ease-in-out;
//    .circle {
//        transform: scale(1.1) translateX(-120px);
//        height: 30px;
//        background-color: $white;
//        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
//
//        .search-icon__input {
//            padding: 2px 5px;
//            width: 100px;
//        }
//    }
//    .handle {
//        top: 14px;
//        left: 17px;
//        &:after {
//            transform-origin: center center;
//            transform: rotate(90deg);
//        }
//    }
//}
//
///* SEARCH BAR FORM */
//
//.searchBar {
//    position: absolute;
//    right: 0;
//    // background-color: $pink;
//    width: 810px;
//    height: 50px;
//    @media only screen and (min-width: 1200px) {
//        opacity: 0;
//        pointer-events: none;
//        transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;
//    }
//    @media #{$navBreak} {
//        position: static;
//        width: 100%;
//    }
//
//    &__input {
//        width: 100%;
//        height: 100%;
//        background-color: transparent;
//        color: $white;
//        border: none;
//        border-bottom: 2px solid $white;
//        @media #{$navBreak} {
//            color: $black;
//            border-color: $black;
//        }
//    }
//    @media only screen and (min-width: 1200px) {
//        &__open {
//            opacity: 1;
//            pointer-events: visible;
//        }
//    }
//}
//
///* Nav Open */
//
//@media only screen and (min-width: 1200px) {
//    .navbar-nav {
//        li {
//            transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
//        }
//    }
//    .navbar-nav-search-open {
//        li {
//            opacity: 0;
//            transform: scale(0);
//        }
//    }
//}
//
//.header {
//    &.header {
//        &--shown {
//            &:not(.header--top){
//                .search-icon {
//                    .circle{
//                        border-color: $text;
//                    }
//
//                    .handle, .handle::after {
//                        background-color: $text;
//                    }
//                }
//            }
//        }
//    }
//}

@media #{$navBreak} {
    .header-search {
        &__wrapper {
            width: 100%;
        }

        &__input {
            width: 100%;
            margin-top: 0.5rem;
            padding: 0.375rem 0.75rem;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
        }
    }
}

@media screen and (min-width: 1198.98px) {
    $searchWidth: 150px;
    $searchHeight: 24px;
    $searchBorderWidth: 3px;
    .header-search {
        width: $searchHeight;
        height: $searchHeight;
        position: absolute;
        transition: width 0.5s, transform 0.5s, height 0.5s;
        transition-delay: 0.5s;
        z-index: 3;

        &__wrapper {
            position: relative;
            width: $searchHeight;
            height: $searchHeight;
            margin-left: 10px;
        }

        &__label {
            &-init {
                z-index: 2;
                position: absolute;
                width: $searchHeight;
                height: $searchHeight;
            }

            &-active {
                z-index: 2;
                position: absolute;
                width: 0;
                height: 0;
            }
        }

        &__border {
            position: relative;
            width: $searchHeight;
            height: 100%;
            border-radius: 5rem;
            border: $searchBorderWidth solid #fff;
            transition: width 0.5s, background-color 0.5s;
            transition-delay: 0.5s;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                height: calc(70% - #{$searchBorderWidth * 2});
                width: $searchBorderWidth;
                background-color: #fff;
                border-radius: 5rem;
                right: 0;
                bottom: -8px;
                transform-origin: 0 0;
                transform: rotateZ(-45deg);
                transition: opacity 0.5s, transform 0.5s;
            }
        }

        &__input {
            position: absolute;
            width: $searchWidth;
            height: 100%;
            padding: 5px $searchHeight 5px 10px;
            background: transparent;
            outline: none;
            border: none;
            font-size: 18px;
            color: #fff;
            z-index: -1;
            opacity: 0;
            transition: opacity 0.2s;
            top: 0;

            &::placeholder {
                color: $white;
            }
        }

        &__close {
            position: absolute;
            width: $searchHeight;
            height: 100%;
            cursor: pointer;
            top: 0;
            right: 0;
            margin-right: #{$searchBorderWidth * 2};

            &::after,
            &::before {
                content: "";
                position: absolute;
                height: calc(100% - #{$searchBorderWidth * 4});
                width: $searchBorderWidth;
                background-color: #fff;
                border-radius: 5rem;
                top: 50%;
                left: 50%;
                transform-origin: 0 0;
                transform: rotateZ(-45deg) translateX(-50%) translateY(100%);
                opacity: 0;
                transition: opacity 0.5s, transform 0.5s;
            }

            &::before {
                transform: rotateZ(45deg) translateX(-50%) translateY(-200%);
            }
        }

        &.active {
            width: $searchWidth;
            height: #{$searchHeight * 1.4};
            transform: translateX(calc(#{$searchHeight} - 100%)) translateY(-#{($searchHeight * 0.4) / 2});
            transition-delay: 0s;

            .header-search {
                &__input {
                    opacity: 1;
                    z-index: 1;
                }

                &__border {
                    width: $searchWidth;
                    transition-delay: unset;
                    background-color: $secondary;
                }

                &__label {
                    &-init {
                        position: absolute;
                        width: 0;
                        height: 0;
                    }

                    &-active {
                        position: absolute;
                        width: $searchHeight;
                        height: $searchHeight;
                    }
                }

                &__close {
                    z-index: 3;

                    &::before,
                    &::after {
                        opacity: 1;
                    }

                    &::before {
                        transform: rotateZ(45deg) translateX(-50%) translateY(-50%);
                    }

                    &::after {
                        transform: rotateZ(-45deg) translateX(-50%) translateY(-50%);
                    }
                }
            }
        }
    }

    .header.header--shown:not(.header--top),
    .header:not(.header-home).header--shown.header--top .header-nav.header-nav--no-background-image {
        .header-search {
            &__border {
                border-color: $text;

                &::after {
                    background-color: $text;
                }
            }
        }
    }
}

/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */
/* ==================================================================== */

.search-button {
    position: relative;
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    @media #{$navBreak} {
        display: none;
    }

    .search-icon {
        fill: $white;
        &-close {
            fill: $white;
            display: none;
        }
    }
    &.active {
        .search-icon {
            display: none;
            &-close {
                display: block;
            }
        }
    }
}

.search-form {
    position: absolute;
    right: 0;
    bottom: -40px;
    display: none;
    @media #{$navBreak} {
        display: block;
        position: relative;
        width: 100%;
        margin-bottom: 2em;
        &__input:focus{
            // border: 0;
            outline: 0;
            box-shadow: none;
        }
    }

    &-show {
        display: block;
    }
}

.header.header--shown:not(.header--top),
.header:not(.header-home).header--shown.header--top .header-nav.header-nav--no-background-image {
    .search-button {
        .search-icon {
            fill: $text;
            &-close {
                fill: $text;
            }
        }
    }
}
