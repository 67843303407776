.homepage {
    &-title {
        font-size: 36px;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
        }
    }
}

.video-model {
    background-color: rgba($black, 0.5);
}

/* ============================================== */
/* CONTENT SECTION */
/* ============================================== */

#content__section--4 {
    .btn {
        background-color: transparent;
        border: 2px solid $white;
        transition: all 200ms ease-in-out;
        &:hover {
            border-color: $black;
            color: $white;
            background-color: $black;
        }
    }
}

.content__section {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include media-breakpoint-down(xs) {
        .tagged-image__pill {
            display: none;
        }
    }

    &--testimonials {
        .content__section {
            &--title {
                text-align: center;
            }

            &--button-container {
                justify-content: center;
            }
        }
    }

    &--content-container {
        max-width: 100%;
    }

    &--button-container {
        padding-top: 20px;
    }

    &--swiper-testimonials {
        max-width: 100%;

        &--content-inner {
            padding: 0 100px;
            margin: 0 auto;
            text-align: center;
            font-size: 24px;

            @include media-breakpoint-down(sm) {
                padding: 0 40px;
                font-size: 16px;
            }
        }

        &--title {
            font-size: 18px;
            color: $secondary;
            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            &::after {
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }
        }
    }

    &--shape {
        > .container {
            position: relative;
            padding-top: 100px;
            padding-bottom: 100px;
        }

        &.has-background-image {
            background: linear-gradient(
                90deg,
                rgba(210, 214, 219, 1) 0%,
                rgba(210, 214, 219, 1) 0%,
                rgba(210, 214, 219, 0.89) 45%,
                rgba(210, 214, 219, 0) 65%
            );

            @include media-breakpoint-down(xs) {
                background: transparent;
            }
        }
    }

    &--video-container {
        iframe {
            max-width: 100%; //acf adds dimensions by default to iframes so fix is to the parent
        }
    }

    &--video-container,
    &--content-container {
        flex: 50%;

        @include media-breakpoint-down(sm) {
            flex: 100%;
        }
    }

    &--has-video {
        padding-left: 50px;
    }

    $triangle-height: var(--triangle-height);
    $sm-triangle-height: var(--sm-triangle-height);

    //cutout on the bottom right of a shape
    &:nth-child(2n + 1) {
        @include cutout(-bottom-right);

        @include media-breakpoint-down(sm) {
            @include cutout(small--bottom-right);
        }
    }

    &:nth-child(2n + 2) {
        .content__section {
            &--video-container {
                order: 1;
            }
        }

        .tagged-image__pill {
            left: unset;
            right: 10px;
        }
    }

    &:nth-child(4n + 3) {
        @include cutout(-bottom-left);

        @include media-breakpoint-down(sm) {
            @include cutout(small--bottom-left);
        }
    }

    //the next element needs to align to the cutout
    &:nth-child(4n + 4) {
        .content__section {
            &--video-container {
                order: 0;
            }

            &--shape {
                &.has-background-image {
                    background: linear-gradient(
                        -90deg,
                        rgba(210, 214, 219, 1) 0%,
                        rgba(210, 214, 219, 1) 0%,
                        rgba(210, 214, 219, 0.89) 45%,
                        rgba(210, 214, 219, 0) 65%
                    );
                }
            }

            &--image-alt {
                .tagged-image__pill {
                    left: 10px;
                    right: unset;
                }
            }

            &--has-video {
                padding-left: 0;
                padding-right: 50px;
            }
        }
    }

    &:not(:nth-child(1)) {
        //not the first element
        .content__section {
            &--shape {
                padding-top: $triangle-height;
                margin-top: calc(-1 * #{$triangle-height});

                @include media-breakpoint-down(sm) {
                    padding-top: $sm-triangle-height;
                    margin-top: calc(-1 * #{$sm-triangle-height});
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .content__section {
            &--button-container {
                padding-top: 40px;
            }

            &--has-video {
                padding: 0;
            }
        }
    }
}

/* ============================================== */
/* ABOUT SECTION */
/* ============================================== */

.about-us-section {
    padding: 75px 0;
    padding-bottom: 130px;
    @media only screen and (max-width: 575px) {
        padding-bottom: 75px;
    }
}

/* ============================================== */
/* Services Carousel */
/* ============================================== */
.services__carousel {
    &--slide {
        height: auto;

        .card {
            height: 100%;
        }
    }
}

/* ============================================== */
/* NEWS SECTION */
/* ============================================== */

.latest-news {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #009ee0;

        @include cutout(-bottom-right);

        @include media-breakpoint-down(md) {
            @include cutout(small--bottom-right);
        }
    }

    &__carousel {
        &--slide {
            display: flex;
            height: unset;
        }

        &--pagination {
            @include media-breakpoint-down(xs) {
                .swiper-button-prev,
                .swiper-button-next {
                    display: none;
                }
            }
        }
    }

    &__container {
        display: flex;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;

            .latest-news {
                &__content,
                &__social {
                    flex: 1 0 100%;
                    max-width: 100%;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__content {
        //        padding-top: 100px;
        //        padding-bottom: 100px;
        max-width: calc(100% - 276px);
        flex: 1 0 0;

        &--title {
            position: relative;
            z-index: 1;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }

    &__social {
        flex: 0 0 276px;
        margin-top: -80px;
        margin-bottom: 80px;

        &--title {
            color: #009ee0;
            font-size: 30px;
        }

        &--post {
            &-image {
                max-width: 100%;
                height: 150px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-bottom: 15px;
                @media only screen and (max-width: 991px) {
                    height: 300px;
                }
            }

            &-title {
                position: relative;
                z-index: 2;
                padding: 0.5em 1em;
                background-color: rgba($black, 0.8);
                color: $white !important;
                border-radius: 2rem;
                margin: -10px 10px -10px -10px;
                display: flex;
                align-items: center;
                transition: background-color 200ms ease-in-out;
                img {
                    margin-right: 5px;
                }
                span {
                    max-height: 17px;
                    font-size: 14px;
                    font-weight: bold;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $black;
                }
            }
        }
    }
}

/* ============================================== */
/* ACCREDITATIONS SECTION */
/* ============================================== */

.accreditations {
    &__logos {
        &--carousel {
            .swiper-slide {
                width: auto;
            }
        }
    }
}

.accreditation {
    &__card {
        height: 96px;
        padding: 15px;
        line-height: 66px;

        &--image {
            max-height: 66px;
        }
    }
}

/* ============================================== */
/* ============================================== */
