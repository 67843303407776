/* Contact */
.header-contact {
  background-color: $third;

  @media (screen-only and max-width: 1199px) {
    .container {
      display: flex;
      justify-content: end;
      height: 100%;
    }
  }

  &__col {
    display: flex;
    align-items: center;
  }

  &__blurb {
    position: relative;
    z-index: 2;
    font-size: 1rem;

    &--icon {
      width: 10px;
      height: 10px;
      margin-right: 8px;
      margin-top: -2px;
      display: inline-block;
      background-color: $primary;
      transform: rotateZ(45deg);
    }

    a {
      text-decoration: underline;
      color: $text !important;
    }
  }

  &__btn-wrap {
    justify-content: flex-end;
    .header-phone {
      color: $black;
      font-weight: 700;
    }
    a + a {
      margin-left: 1em;
    }
  }

  &-phone {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    transition: color 250ms ease-in-out;
    color: $text !important;

    i {
      font-size: 1.8em;
      color: $primary;
      transform: rotate(11deg);
    }

    &:hover {
      text-decoration: none;
    }
  }

  @media #{$navBreak} {
    background: white;
    height: 100px;

    &__blurb {
      display: none;
    }

    &__btn-wrap {
      .btn {
        display: none;
      }
    }
  }
}

/* ============================================== */

/* ============================================== */

.header-logo {
  position: absolute;
  width: 100%;

  > .container {
    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-shadow {
    filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));
    position: relative;
    z-index: 1;
    pointer-events: none;
  }

  &-link {
    display: inline-block;
    //clip-path: polygon(calc(50% - 12px) calc(100% - 20px), 3px 0, calc(100% - 30px) 0);
    // clip-path: polygon(calc(50% - 10px) calc(100%), -5px 0, calc(100%) 0);
    z-index: 1;
    position: relative;
    pointer-events: all;
    margin-left: 0;
    margin-top: 67px;

    @media (screen-only and max-width: 1199px) {
      margin-left: 0px;
      margin-top: 0px;

      .header-logo-img {
        svg {
          height: 95px;
        }
      }
    }
  }

  &-img {
    position: relative;
    z-index: 1;
    width: 422px;

    @media #{$navBreak} {
      margin-left: 0;
      max-width: 300px;
      height: 80px;
      width: auto;

      svg {
        width: auto;
        height: 100%;
      }
    }
  }
}
/* ============================================== */

body:not(.home) {
  .header {
    &--spacer {
      height: 0;
      transition: all 0.3s ease-out;

      &.header--fixed {
        height: 200px;

        @media #{$navBreak} {
          height: 70px;
        }

        @media only screen and (max-width: 767px) {
          height: 50px;
        }
      }
    }
  }
}

body.home {
  .header {
    &--top {
      .header-nav {
        background: transparent !important;

        .navbar .navbar-nav .menu-item:not(.active) .nav-link {
          color: $white;

          &.dropdown-toggle {
            &::after {
              color: $white;
              @media #{$navBreak} {
                color: $secondary;
              }
            }

            &:hover {
              &::after {
                color: $secondary;
              }
            }
          }
        }

        @media #{$navBreak} {
          background: $white !important;

          .navbar .navbar-nav .menu-item:not(.active) .nav-link {
            color: $text;
          }
        }
        .search-button {
          .search-icon {
            fill: $white;
            &-close {
              fill: $white;
            }
          }
        }
      }
    }
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 1;
  transform: translateY(-250px);
  transition: all 0.3s ease-out;
  background-color: transparent;
  z-index: 999;

  &-nav {
    position: relative;
    min-height: 160px;
    display: flex;
    align-items: center;

    @media #{$navBreak} {
      background: $white !important;
      position: fixed;
      right: 0;
      background-color: rgba($white, 0.95);
      width: 450px;
      height: calc(100vh - 60.8px);
      display: block;
      transform: translateX(450px);
      transition: transform 300ms ease-in-out;
      overflow-y: auto;
      &-show {
        transform: translateX(0);
      }
    }

    @media only screen and (max-width: 500px) {
      width: 320px;
      z-index: 999;
    }

    transition: background-color 0.25s;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      transition: background-color 0.5s;
    }
  }

  &--shown {
    transform: translateY(0);
    transform-origin: top;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transition: transform 500ms ease-in-out, background-color 500ms ease-in-out;

    &:not(.header--top) .header-nav {
      background-image: none !important;
      background-color: transparent !important;

      &::before {
        background-color: white;
      }
      // .navbar .navbar-nav .menu-item .nav-link {
      //     color: $text !important;

      //     &.dropdown-toggle {
      //         &::after {
      //             color: $text !important;
      //         }
      //     }
      // }
    }
  }

  // .header-nav.header-nav--no-background-image {
  //     .navbar .navbar-nav .menu-item:not(.active) .nav-link {
  //         color: $text !important;

  //         &.dropdown-toggle {
  //             &::after {
  //                 color: $text;
  //             }
  //         }
  //     }
  // }

  &--hide,
  &--show {
    background-color: rgba($white, 0.95);

    .header-nav {
      min-height: 100px;
    }
    .header-logo {
      &-img {
        width: 400px;
        @media only screen and (max-width: 767px) {
          width: 200px;
        }
      }
    }

    // .navbar .navbar-nav .nav-item .nav-link {
    //     color: $text !important;

    //     &.dropdown-toggle {
    //         &::after {
    //             color: $text;
    //         }
    //     }
    // }

    .search-icon {
      .circle {
        border-color: $black;
      }

      .handle {
        background-color: $black;
        &:after {
          background-color: $black;
        }
      }
    }

    .searchBar__input {
      border-color: $black;
      color: $text;
    }
  }
}

.header--shown:not(.header--top) .header-nav,
.header .header-nav.header-nav--no-background-image,
.header--hide,
.header--show {
  .navbar .navbar-nav .menu-item:not(.active) .nav-link {
    color: $text;

    &.dropdown-toggle {
      &::after {
        color: $text;
      }

      &:hover::after {
        color: $secondary;
      }
    }
  }

  .search-button {
    .search-icon {
      fill: $text;
      &-close {
        fill: $text;
      }
    }
  }
}

.header-logo-img svg .text-grey {
  fill: #7e8798;
}

.header-logo-img svg .text-blue {
  fill: #006bab;
}

@media (min-width: 1199px) {
  .header--top .header-logo-img svg .text-grey,
  .header--top .header-logo-img svg .text-blue {
    fill: white;
  }
}

.mobile-call-modal {
  height: 0%;
  overflow: hidden;
  width: 100%;
  position: absolute;
  z-index: 999;
  display: flex;
  transition: 0.3s;

  .modal-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
  }

  .modal-inner {
    position: relative;
    background: white;
    margin: auto;
    padding: 1rem;
    border-radius: 0.25rem;
    width: 80%;
    opacity: 0;

    .title-row {
      display: flex;
      justify-content: space-between;

      h3 {
        color: $secondary;
        font-weight: 500;
      }
    }
    .link-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

.mobile-call-modal.active {
  height: 100%;
  .modal-inner {
    opacity: 1;
  }
}
