.location {
    &__categories {
        &--category {
            display: flex;

            &--icon {
                width: 10px;
                height: 10px;
                transform-origin: center;
                transform: rotateZ(45deg);
                margin-top: 5.5px;
                margin-right: 8px;
            }
        }
    }

    &__heading{
        font-size: 20px;
        color: $secondary;
    }

    &__content {
        &--phone {
            font-size: 16px;
            font-weight: 500;
            .fa-phone{font-size: 18px;}
        }

        &--fax {
            font-size: 14px;
        }
        
        &--bath {
            &-title {
                font-size: 14px;
                margin-bottom: 0;
                margin-top: 5px;
                color: $text;
            }
        }

        &--bath-size{
            font-size: 14px;
        }
    }

    &__contacts {
        &--contact {
            display: flex;
            margin: 5px;

            &--img {
                margin-right: 5px;
                width: 50px;
            }

            &-content {
                line-height: 1.1;
            }
        }
    }

}

.single-locations {
    .sidebar__content {
        ul {
            padding-left: 0;
            // li {
                
            // }
        }
    }

    .location__contacts--contact{
        margin-bottom: 1em;
    }

    .location__contacts--contact-content{
       & > div{margin-bottom: 5px;}
       a{color: $secondary !important;}
    }

    // .btn.cursor-pointer{
    //     font-size: 16px;
    //     letter-spacing: 0;
    // }

    .accreditation--logo {
        height: 75px;

        img {
            width: 100%;
        }
    }
}

.cluster{
    background-image: url('img/icons/map-cluster.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    img{display: none;}
}