.single-services {
    .sidebar > div:nth-child(2) {
        order: 1;
    }
}

.page-services,
.single-services {
    .cutout-small--top-right.bg-secondary {
        position: relative;
        min-height: 290px;
        @media only screen and (max-width: 991px) {
            min-height: 335px;
        }

        .text-white.font-weight-bold.btn__arrow.btn__arrow--white {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1em;
            background-color: transparent;
            align-items: flex-end;

            &::after {
                height: 24px;
            }
        }
    }
}
