.page-accreditations {
    .accreditations {
        .shadow {
            box-shadow: none !important;
        }

        &__thumbnail {
            box-shadow: 0 0.3rem 0.7rem rgba(0, 0, 0, 0.15);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        // .accreditation__content{
        //     border: 1px solid $fourth;
        // }
    }
}
