.video-page-row {

    &__col{
        margin-bottom: 1.5em;
    }

    &__link{
        display: block;
        height: 100%;

        .card{
            height: 100%;
        }
    }
}