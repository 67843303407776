html {
    height: 100%;
    overflow-x: hidden;
}

body {
    font-family: effra, sans-serif;
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.005em;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    color: $text;
}

footer {
    margin-top: auto;
}

/* HEADER */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

h1 {
    font-size: 60px;
    letter-spacing: -0.005em;
    line-height: 1.1;
    @media only screen and (max-width: 575px) {
        font-size: 42px;
    }
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

img,
iframe,
video {
    max-width: 100%;
}

.scale--hover,
.scale--hover-next > div {
    transition: transform 0.5s;

    &:hover {
        transform: scale(1.05);
    }
}

.arrow--hover {
    &:hover {
        .btn__arrow,
        .btn__arrow--backwards {
            &::after {
                transform: translateX(10px);
            }
        }
    }
}

.no-underline {
    &:hover {
        text-decoration: none;
    }
}

a[href],
.cursor-pointer {
    cursor: pointer;
}

a[href]:not(.btn):not(.btn__arrow):not(.dropdown-item):not(.nav-link) {
    color: $secondary;
}

.default-text {
    color: $text;
}

body:not(.home) h1 {
    padding-top: 64px;
    @media only screen and (max-width: 575px) {
        padding-top: 25px;
    }
}
