.hero {
    .modal-dialog {
        max-width: 800px;
        margin: 30px auto;
    }

    .modal-body {
        position: relative;
        padding: 0px;
    }
    .close {
        position: absolute;
        right: -30px;
        top: 0;
        z-index: 999;
        font-size: 2rem;
        font-weight: normal;
        color: #fff;
        opacity: 1;
    }
}

.hero {
    background-color: $dark;
    position: relative;

    &__sliderContainer {
        width: 100%;
        height: 100%;
    }

    &__sliderItem {
        overflow: hidden;
        height: calc(85vh - 45px);
        min-height: 800px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: $white;
        @media only screen and (max-width: 575px) {
            height: unset;
            min-height: 650px;
        }
    }

    &__sliderContent {
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($black, 0.5);
        @media only screen and (max-width: 575px) {
            justify-content: flex-start;
        }

        &Inner {
            margin-top: 185px;
            width: 575px;
            font-size: 1.2rem;
            letter-spacing: 0.01em;
            @media only screen and (max-width: 768px) {
                width: 100%;
            }
            h1,
            h2 {
                font-size: 64px;
                line-height: 65px;
                font-weight: 400;

                @include media-breakpoint-down(sm) {
                    font-size: 32px;
                    line-height: 36px;
                }
            }
        }
    }

    &__btnWrap {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        padding-bottom: 4em;
        display: flex;
        justify-content: flex-end;
        @media only screen and (max-width: 991px) {
            justify-content: flex-start;
        }
        @media only screen and (max-width: 575px) {
            padding-bottom: 2em;
            left: -15px;
            transform: none;
        }
    }

    &__btn {
        width: 210px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.video-btn {
    color: $white;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        margin-left: -10px;
    }

    i {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $secondary;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.8em;
    }

    &:hover {
        //background-color: $secondary;
        color: $white;

        i {
            background-color: darken($secondary, 5%);
        }
    }
}

.vacancy-modal-header {
    border-bottom: none;
    padding-bottom: 0px; 
}
