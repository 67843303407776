.events {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    & > div.col-12:last-child {
        text-align: center;
    }

    &__event {
        padding: 10px;

        &--card {
            &:hover {
                text-decoration: none;
            }

            &-body {
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(sm){
                    flex-wrap: wrap;
                }
            }
        }

        &--content {
            color: $text;
        }

        &:nth-child(1), &:nth-child(2), &:nth-child(3){
            flex: 0 0 100%;

            .events__event {
                &--card {
                    &-body {
                        flex-direction: row;
                    }
                }

                &--image {
                    flex: 0 0 50%;

                    @include media-breakpoint-down(sm){
                        flex: 0 0 100%;
                        height: 150px;
                    }
                }

                &--content {
                    margin: 10px;
                }
            }
        }

        &:nth-child(2){
            .events__event {
                &--image {
                    order: 1;

                    @include media-breakpoint-down(sm){
                        order: 0;
                    }
                }
            }
        }

        &:nth-child(n + 4){
            flex: 0 0 (100% / 3);

            @include media-breakpoint-down(md){
                flex: 0 0 50%;
            }

            @include media-breakpoint-down(sm){
                flex: 0 0 100%;
            }

            .events__event {
                &--image {
                    height: 150px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}