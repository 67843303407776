.green-credentials {

    .wp-block-image img {
        width: 100%;
    }


    h1 {
        span {
            color: #65DF80;
            font-weight: bold;
        }
    }

    &__secondary {
        &-section {
            background: #DFE1E5;
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__image {
        img {
            height: auto;
            width: 100%;
        }
    }

    .illustration-description {
        h2 {
            color: #65DF80;
        }
    }
}

.animation {
    position: relative;
    display: inline-block;

    &-button {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: fit-content;
        margin: auto;
        z-index: 10;
    }

    &-overlay {
        position: absolute;
        z-index: 9;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.4;
        top: 0px;
        transition: 0.3s;
    }

    &:hover .animation-overlay {
        opacity: 0.2;
    }
}