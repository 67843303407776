.photo-gallery {
    display: flex;
    transition: transform 0.5s;

    & > * {
        flex: 0 0 100%;
    }

    .card{
        height: 100%;
        overflow: hidden;
        &--title{
            font-weight: 300;
            font-size: 20px;
        }
    }

    &__images {
        .card-body {
            padding: 0;
        }

        .card-img-container {
            margin-bottom: 0 !important;
        }

        .card--image {
            margin-bottom: 0 !important;
        }
    }
}

.page-photo-gallery {
    #next-page-button {
        margin-left: 0.5rem !important;
    }
}