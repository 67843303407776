.btn {
    font-weight: normal;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0;
    transition: background-color 300ms ease-in-out;

    + .btn {
        margin-left: 16px;
    }

    &__header {
        padding: 0.25rem 15px;
        font-size: 0.875rem;
        font-weight: 700;
    }

    &__arrow {
        font-weight: 700;
        color: $black;
        display: flex;
        align-items: center;
        padding-right: 23px;

        &:after {
            content: "";
            display: inline-block;
            width: 23px;
            height: 14px;
            background-image: url(../img/icons/arrow-right.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-left: 0.5em;
            transition: transform 250ms ease-in-out;
        }
        &:hover {
            text-decoration: none;
            color: currentColor;
            opacity: 1;

            &:after {
                transform: translateX(10px);
            }
        }

        &--white {
            &:after {
                background-image: url(../img/icons/arrow-right-white.svg);
            }
        }

        &--backwards {
            padding-right: 0;
            padding-left: 28px;
            position: relative;

            &::after {
                position: absolute;
                left: 0;
                margin-left: 0;

                transform: rotateZ(180deg);
            }

            &:hover {
                &::after {
                    transform: rotateZ(180deg) translateX(10px);
                }
            }
        }
    }
}

.btn-outline-white {
    border: 2px solid;
    transition: color ease-in-out 250ms, border ease-in-out 250ms, background-color 250ms ease-in-out;
    @include button-variant(transparent, $white);

    &:hover {
        border-color: $black;
        color: $white;
        background-color: $black;
    }
}

.btn-outline-primary,
.btn-outline-secondary {
    border: 2px solid;
}

//THIS IS USED BY JS FILTER FUNCTION FOR LOAD MORE BUTTON
.load-more {
    @extend .btn;
    @include button-variant($secondary, $secondary);
    color: $white !important;
}

.close-btn {
    position: absolute;
    top: 28%;
    left: 66%;
    transform: translate(-66%, -28%);
    width: 25px;
    height: 25px;
    cursor: pointer;

    .search-icon-close path {
        fill: $white;
    }

    &:hover {
        .search-icon-close path {
            fill: $secondary;
        }
    }
}
