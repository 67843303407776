.sidebar {
    h4 {
        font-size: 24px;
    }

    &__content {
        &--border {
            background-color: #b0b0b0;
            padding: 1px;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                margin: 0;
                margin-bottom: 0.5em;
                padding-left: 1em;
                position: relative;

                &:after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    background: #939ca9;
                    display: block;
                    position: absolute;
                    transform: rotate(45deg);
                    top: 0.35em;
                    left: 0;
                }
            }
        }
    }
}
