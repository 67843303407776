$triangle-height: 24px;
$triangle-hypotenuse: 21px;
$triangle-width: 17.2336879px; //sqrt(($triangle-hypotenuse)^2 - ($triangle-height/2)^2)
$triangle-angle: 34.85deg;
/*
               arccos(
                   (($triangle-hypotenuse)^2 + ($triangle-height / 2)^2) - ($triangle-width)^2)
                   / (2 * $triangle-hypotenuse * ($triangle-height / 2))
               )
           */

.history {
    &__timeline {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: calc(50% - 2px);
            top: 0;
            height: 100%;
            width: 4px;
            background-color: #D8D8D8;

            @include media-breakpoint-down(sm) {
                left: calc(100% - 2px);
            }
        }

        &--item {
            padding: 5px;
            padding-right: 10px;
            border-radius: 10px;
            border-right: 1px solid #D8D8D8;

            @extend .shadow;

            &-container {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: calc(50% - 9px);
                    top: 12px;
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                    border: 2px solid $white;
                    background-color: $secondary;
                    z-index: 1;

                    @include media-breakpoint-down(sm) {
                        left: calc(100% - 9px);
                    }
                }

                &:nth-child(even) {
                    @include media-breakpoint-up(md) {
                        .history__timeline {
                            &--item {
                                padding-left: 10px;
                                border-right: 0;
                                border-left: 1px solid #D8D8D8;
                                box-shadow: -1px 1px 1px 1px rgba($black, 0.2);

                                &-wrapper {
                                    margin-left: 50%;
                                    padding-left: 30px;
                                    padding-right: 0;
                                }

                                &-triangle {
                                    left: 10px;
                                    right: unset;

                                    &::before, &::after {
                                        transform-origin: 100% 50%;
                                    }

                                    &::before {
                                        transform: rotateZ(-$triangle-angle);
                                    }

                                    &::after {
                                        transform: rotateZ($triangle-angle);
                                    }
                                }

                                &--title {
                                    order: 1;
                                }

                                &--content {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }

            &-wrapper {
                width: 50%;
                padding-right: 30px;
                position: relative;
                margin: 10px 0;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            &-triangle {
                position: absolute;
                height: $triangle-height;
                width: $triangle-hypotenuse;
                right: 10px;
                top: 10px;
                background-color: $white;

                &::after, &::before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 21px;
                    background-color: #D8D8D8;
                    transform-origin: 0 50%;
                }

                &::before {
                    top: -1px;
                    transform: rotateZ($triangle-angle);
                }

                &::after {
                    bottom: -1px;
                    transform: rotateZ(-$triangle-angle);
                }
            }

            &--title {
                font-size: 24px;
                display: flex;
                align-items: center;
            }

            &--year {
                color: $secondary;
                font-size: 32px;
            }
        }
    }
}