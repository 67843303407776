.sidebar-documents {
    background-color: #CACACA;

    &__title {
        color: $black;
    }

    &__document {
        color: $black;
        margin-bottom: 10px;
        font-weight: normal;

        &:hover {
            color: $black;
        }
    }
}