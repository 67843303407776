.accreditation {
    &__content {
        &--link {
            color: $body-color;

            &:hover {
                color: $black;
            }
        }
    }
}