/*
Theme Name: Dimakin
Author: Saydur Rahman
Description: Custom Bootstrap 4 Woocomerce theme.
Version: 1.0

* Copyright © 2019 Dimakin All rights reserved. Code or files can not be copied, modified and/or distributed without the express written permission. Unauthorized copying, modification or use of this file, via any medium is strictly prohibited.
*/

// VARS
@import "variables";

// SWIPER
@import "~swiper/swiper-bundle.min.css";

/* BOOTSTRAP 4 */
@import "~bootstrap/scss/bootstrap";

// AOS
@import "~aos/src/sass/aos";

// CUSTOM
@import "global";

@import "components/components";

@import "layout/layout";
@import "pages/pages";
@import "re-write/re-write";

.grecaptcha-badge {
  visibility: hidden;
}
