.downloads {
    &__category {
        &--documents {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;

            &--document {
                padding: 5px 15px;
                flex: 0 0 (100% / 3);

                @include media-breakpoint-down(md) {
                    flex: 0 0 50%;
                }

                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                }

                .card {
                    height: 100%;
                }
                .card--content-container .btn__arrow {
                    color: $secondary;

                    &::after {
                        margin-top: -3px;
                    }
                }
            }
        }
    }
}
