.resources {
    &__card {
        &--title {
            font-size: 24px;
        }

        &--content {
            img {
                max-width: 100%;
            }
        }
    }
}