$triangle-height: var(--triangle-height);
$sm-triangle-height: var(--sm-triangle-height);

$paths: (
    "-top-left": polygon(100% 0%, 0% 0%, 0% 100%, 0 $triangle-height, $triangle-height 0),
    "-top-right": polygon(calc(100% - #{$triangle-height}) 0, 100% $triangle-height, 100% 100%, 0 100%, 0 0),
    "-bottom-left": polygon(100% 0, 0 0, 0 calc(100% - #{$triangle-height}), $triangle-height 100%, 100% 100%),
    "-bottom-right": polygon(100% 0%, 0% 0%, 0% 100%, calc(100% - #{$triangle-height}) 100%, 100% calc(100% - #{$triangle-height})),

    "small--top-left": polygon(100% 0%, 0% 0%, 0% 100%, 0 $sm-triangle-height, $sm-triangle-height 0),
    "small--top-right": polygon(calc(100% - #{$sm-triangle-height}) 0, 100% $sm-triangle-height, 100% 100%, 0 100%, 0 0),
    "small--bottom-left": polygon(100% 0, 0 0, 0 calc(100% - #{$sm-triangle-height}), $sm-triangle-height 100%, 100% 100%),
    "small--bottom-right": polygon(100% 0%, 0% 0%, 0% 100%, calc(100% - #{$sm-triangle-height}) 100%, 100% calc(100% - #{$sm-triangle-height})),
);


.cutout {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $infix: breakpoint-infix($breakpoint);

        &#{$infix} {
            @include media-breakpoint-up($breakpoint) {

                @each $location, $path in $paths {
                    &-#{$location} {
                        clip-path: $path;
                    }
                }

            }
        }
    }
}

@mixin cutout($size){
    $path: map-get($paths, $size);

    clip-path: $path;
}