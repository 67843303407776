.testimonials {
    &__image-container {
        align-items: center;
        &--image {
            width: 100%;
            // @media screen and (min-width: 350px) and (max-width: 576px) {
            //     width: 50%;
            // }
        }
    }
}
