.sidebar-navigation {
    &__nav {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        &--container {
        }

        &--item {
            min-height: 28px;
            margin-bottom: 5px;
        }

        &--link {
            color: $white !important;
            position: relative;
            transition: color ease-in-out 250ms;
            font-weight: normal;
            // transition: color 0.5s, border-color 0.5s;
            // border-bottom: 1px solid transparent;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 1px;
                width: 100%;
                height: 2px;
                background: currentColor;
                transform: translateY(2px);
                opacity: 0;
                transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
            }

            &-active {
                &:after {
                    transform: translateY(0);
                    opacity: 1;
                    // transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
                }
            }

            &:hover {
                // color: $secondary !important;
                // border-color: $secondary;
                text-decoration: none;

                &:after {
                    transform: translateY(0);
                    opacity: 1;
                    // transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
                }
            }
        }
    }
}
