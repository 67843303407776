.powder-coat-colours {
    &__categories {
        padding-bottom: 20px;

        &--category {
            display: block;
            padding: 5px 15px;

            &--active {
                border-bottom: 1px solid $secondary;
            }
        }
    }

    &__container {
        > div:last-child {
            text-align: center;
        }
    }

    &__card {
        transition: transform .5s;

        &:hover {
            text-decoration: none;

            .powder-coat-colours__card {
                &--title {
                    text-decoration: none;
                }
            }
        }
    }

    &__lightbox {

        @include media-breakpoint-down(sm){
            flex-direction: column;
        }

        &--content {
            flex: 0 0 300px;
            overflow-y: scroll;
            max-height: 100%;
            padding: 10px;

            a {
                color: $secondary;

                &:hover {
                    color: darken($secondary, 5%);
                }
            }

            @include media-breakpoint-down(sm){
                flex: 0 1 auto;
            }
        }

        &--image {
            width: 100%;
            max-height: 100%;
            object-fit: contain;

            &-container, &-wrapper {
                width: 100%;
            }

            &-wrapper {
                max-height: 100%;
                display: flex;
            }
        }
    }
}