.lightbox {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000;

    &--active {
        display: block;
    }

    &--container {
        height: 100%;
        display: flex;
        align-items: center;
    }

    &--wrapper {
        width: 100%;
        height: 100%;
    }

    &__controls {
        position: absolute;
        padding: 0 10px;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: $white;
    }

    &__btn {
        &--next, &--previous {
            position: relative;
            z-index: 2;
            cursor: pointer;
            border: none;
            background: transparent;
            height: 26px;
            width: 16px;

            &::before, &::after {
                content: '';
                position: absolute;
                display: block;
                width: 16px;
                height: 1px;
                background-color: $white;
                transform-origin: 0;
                top: calc(50% - 2px);
            }

            &::before {
                transform: rotateZ(45deg);
            }

            &::after {
                transform: rotateZ(-45deg);
            }
        }

        &--previous {
            &::before, &::after {
                left: 0;
            }
        }

        &--next {
            &::before, &::after {
                right: 0;
                transform-origin: 100%;
            }
        }

        &--disabled {
            &::before, &::after {
                background-color: $black !important;
            }
        }
    }

    &__elements {
        display: flex;
        width: 100%;
        height: 100%;
        transition: transform .5s;
    }

    &__element {
        max-width: 90%;
        max-height: 90%;
        display: flex;

        &:after{
            content:"\f00d";
            font-family: FontAwesome;
            display: block;
            z-index: 999;
            font-size: 2rem;
            font-weight: normal;
            color: #fff;
            opacity: 1;
            margin-left: 0.5em;
            cursor: pointer;
            &:hover{
                color: $secondary;
            }
        }

        &--container {
            flex: 0 0 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px;

            @include media-breakpoint-down(sm){
                padding: 25px;
            }
        }

        &--shown {
            z-index: 1;
        }
    }
}