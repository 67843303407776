.not-found {

    &__content {
        margin-top: 20px;
    }

    &__error {
        font-size: 80px;
        margin: 0;

        @media (min-width: 576px) {
            font-size: 130px;
        }

        @media (min-width: 768px) {
            font-size: 150px;
        }

        @media (min-width: 1200px) {
            font-size: 160px;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 5px;
    }

    &__link {
        text-decoration: none;
    }
}