.esg__sections {
  .content__section {
    .container {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }

  .card {
    height: 100%;
  }
}

.environmental {
  &-card {
    margin-bottom: 1rem !important;
    .card {
      border-color: #016bab;
    }
  }
}

.social {
  &-card {
    margin-bottom: 1rem !important;
    .card {
      &:hover {
        text-decoration: none;
      }

      .card-body {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row !important;

        > div {
          @include media-breakpoint-up(sm) {
            &.card-img-container {
              .card--image {
                height: 150px;
                width: 150px;
              }
            }

            &.card--content-container {
              padding-left: 20px;
              padding-right: 0;

              @include media-breakpoint-down(sm) {
                padding: 0 !important;
              }
            }
          }

          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
          }
        }
      }

      .card--content-container {
        flex: 0 0 69%;
      }
    }
  }
}

.governance-card {
  .card--image {
    height: 210px;
  }
}

.governance-image {
  img {
    height: auto;
  }
}

.governance {
  &-card {
    margin-bottom: 1rem !important;
    .card {
      &:hover {
        text-decoration: none;
      }

      .card-body {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row !important;

        > div {
          @include media-breakpoint-up(sm) {
            &.card-img-container {
              .card--image {
                height: 150px;
                width: 150px;
              }
            }

            &.card--content-container {
              padding-left: 20px;
              padding-right: 0;

              @include media-breakpoint-down(sm) {
                padding: 0 !important;
              }
            }
          }

          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
          }
        }
      }

      .card--content-container {
        flex: 0 0 69%;
      }
    }
  }
}
