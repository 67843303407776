.bath-sizes {
    .bath-size {
        height: 100%;
        &__title {
            margin-bottom: 0;
        }

        &__size {
            font-size: 24px;
            margin-bottom: 10px;
        }

        &__content.default-text{
            position: relative;
            height: 100%;
            padding-bottom: 1.5em;
            .btn__arrow{
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
}