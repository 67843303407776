.media {
    &__featured {
        &--post {

            &:hover {
                text-decoration: none;
            }

            .card-body {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row !important;

                > div {
                    flex: 0 0 50%;

                    @include media-breakpoint-up(sm){
                        &.card-img-container {
                            .card--image {
                                height: 100%;
                                min-height: 150px;
                                max-height: 400px;
                            }
                        }

                        &.card--content-container {
                            padding-left: 20px;
                            padding-right: 0;

                            @include media-breakpoint-down(sm){
                                padding: 0 !important;
                            }
                        }
                    }

                    @include media-breakpoint-down(sm){
                        flex: 0 0 100%;
                    }
                }
            }

            &:nth-child(even){
                .card-img-container {
                    order: 1;

                    @include media-breakpoint-down(sm){
                        order: 0;
                    }
                }
                
                .card--content-container {
                    padding-right: 20px;
                    padding-left: 0;
                }
            }
        }
        
        .col-12 {
            text-align: center;
        }
    }
}

.page-news .media__featured--post:nth-child(2n) .card-img-container{
    order: 0;
}