// Colors
$primary: #006BAB;
$secondary: #009EE0;
$third: #DFE1E5;
$fourth: #828A93;
$ink: #2A2362;
$text: #111111;


$swiper-navigation-size: 24px;
$homepage-triangle-height: 200px;

:root {
    --swiper-navigation-size: #{$swiper-navigation-size};
    --swiper-navigation-color: #{$secondary};

    --homepage-triangle-height: #{$homepage-triangle-height};

    --triangle-height: 200px;
    --sm-triangle-height: 50px;
}