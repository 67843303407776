.card {
  @extend .shadow;

  border-radius: 8px;
  border-color: #c4c4c4;
  flex-grow: 1;

  .card-body {
    display: flex;
    flex-direction: column;

    > div:last-child {
      flex-grow: 1;
    }
  }

  &--image {
    height: 150px;
    width: 100%;
    background: no-repeat center/cover;
  }

  &--title {
    font-weight: $font-weight-normal;
    font-size: 24px;
    color: $text;
  }

  &--content-container {
    color: $text;
    display: flex;
    flex-direction: column;

    .card--content {
      flex-grow: 1;
    }
  }
}

/* =========================== */
/* =========================== */
/* =========================== */

.page-photo-gallery {
  .card {
    &--title {
      font-weight: normal;
      font-size: 20px;
    }
  }
}
