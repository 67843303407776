$navBreak: "only screen and (max-width : 1199px)";
/* ============================================== */

// Hamburger
.navbar-toggler {
    float: right;
    border: none;
    padding-right: 0;

    &:active,
    &:focus {
        outline: none;
    }

    .navbar-toggler-icon {
        width: 24px;
        height: 18px;
        background-image: none;
        position: relative;
        border-bottom: 2px solid $secondary;
        transition: all 300ms linear;

        &:after,
        &:before {
            content: "";
            position: absolute;
            width: 24px;
            height: 2px;
            background-color: $secondary;
            top: 0;
            left: 0;
            z-index: 2;
            transition: all 300ms linear;
        }

        &:after {
            top: 8px;
        }

        //remove bootsrap hamburger icon
        i.fa {
            display: none;
        }
    }

    &[aria-expanded="true"] .navbar-toggler-icon {
        &:after {
            transform: rotate(135deg);
        }
        &:before {
            transform: translateY(8px) rotate(225deg);
        }
        border-color: transparent;
    }
}

.navbar {
    padding: 0;

    @media #{$navBreak} {
        flex-direction: column-reverse;
        align-items: flex-start;
        overflow: hidden;
    }

    //Navigation
    .navbar-nav {
        width: 738px;
        justify-content: space-between;

        @media #{$navBreak} {
            width: 100%;
            margin-top: 2em;
        }

        .nav-item {
            position: relative;
            transition: all 200ms linear;
            z-index: 3;

            // hover underline effect
            // &:after {
            //     content: "";
            //     position: absolute;
            //     width: 100%;
            //     height: 2px;
            //     bottom: 0px;
            //     left: 0;
            //     opacity: 0;
            //     transition: all 200ms linear;

            //     background-color: $secondary;
            // }

            // &:hover::after {
            //     bottom: 5px;
            //     opacity: 1;
            // }

            &.active:hover::after {
                opacity: 0;
            }

            &.dropdown:after {
                width: calc(100% - 22px);
            }

            //Nav Link
            .nav-link {
                position: relative;
                padding: 5px 0;
                display: inline-block;
                transition: all 200ms linear;
                font-weight: 400;
                color: $white;

                @media #{$navBreak} {
                    position: static;
                    padding-top: 0.6em;
                    padding-bottom: 0.6em;
                    width: 100%;
                    color: $text;
                }
            }

            &:hover .nav-link,
            &.active .nav-link {
                color: $secondary !important;

                &.dropdown-toggle::after {
                    color: $secondary !important;
                }
            }
        }
    }
}

/* #Primary style
  ================================================== */

.nav-item {
    .dropdown-toggle {
        padding-right: 22px !important;
        width: auto !important;

        //dropdown arrow
        &:after {
            content: "\f078";
            position: absolute;
            display: inline-block;
            right: 0;
            font-family: "FontAwesome";
            font-size: 0.8em;
            border: none;
            vertical-align: 0;
            line-height: 1.5;
            transition: all 200ms linear;
            color: $white;
            // background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
            // background-repeat: no-repeat;
            // background-position: center center;

            @media #{$navBreak} {
                float: right;
                margin-right: 0.5em;
                color: $secondary;
            }
        }

        &:hover {
            &::after {
                color: $secondary;
            }
        }
    }

    &.show .dropdown-toggle:after {
        transform: rotate(-90deg);
    }

    .dropdown-menu {
        left: 50%;
        transform: translateX(-50%) translate3d(0, 10px, 0);
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        display: block;
        padding: 0;
        margin: 0;
        transition: all 200ms linear;
        background-color: $fourth;
        border-radius: 0;
        filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));

        @include cutout(small--top-right);

        @media #{$navBreak} {
            left: unset;
            transform: unset;
            width: 100%;
            background-image: none;
            background-color: $secondary;
            padding-top: 2em;
            padding-bottom: 2em;
        }
    }

    &.show .dropdown-menu {
        opacity: 1;
        visibility: visible;
        max-height: 999px;
        transform: translateX(-50%) translate3d(0, 0px, 0);
        @media #{$navBreak} {
            transform: translate3d(0, 0px, 0);
        }
    }

    &:last-child {
        .dropdown-menu {
            left: 100%;
            transform: translateX(-100%) translate3d(0, 10px, 0);
            @media #{$navBreak} {
                left: unset;
                transform: unset;
            }
        }

        &.show .dropdown-menu {
            transform: translateX(-100%) translate3d(0, 0px, 0);
            @media #{$navBreak} {
                transform: translate3d(0, 0px, 0);
            }
        }
    }
}

.dropdown-menu {
    padding: 10px !important;
    margin: 0;
    min-width: 18rem;
    border: none;
    border-radius: 3px;
    box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
    transition: all 200ms linear;

    @media #{$navBreak} {
        border-radius: 0;
    }

    .dropdown-item {
        position: relative;
        padding: 3px 15px;
        color: $white;
        border-radius: 2px;
        transition: all 200ms linear;
        font-size: 16px;

        &:hover,
        &:focus,
        &.active {
            text-decoration: underline;
            background-color: transparent;
        }
    }
}

/* #Media
  ================================================== */

@media #{$navBreak} {
    .navbar-collapse {
        width: 100%;
    }
    .nav-item {
        border-bottom: 1px solid darken($light, 10%);
        &:after {
            display: none;
        }
    }

    .dropdown-menu {
        padding: 0 !important;
        background-color: transparent;
        box-shadow: none;
        transition: all 200ms linear;
    }
}

/* Remove Bootstrap Animation */
.collapsing {
    transition: none !important;
}
