.page-news {
    .card--content-container {
        text-align: left;

        .btn__arrow {
            font-size: 16px;
        }
    }

    .category {
        .card--title, .btn__arrow {
            text-align: left;
        }
    }
}
