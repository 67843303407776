.glossary {
    margin-bottom: 64px;
    &__accordion {
        border: none;
        border-radius: 0;

        &--button {
            border: none;
            border-top: 2px solid #CBCBCB;
            border-radius: 0;
            position: relative;
            background-color: #F5F5F5;
            padding-right: 46px;

            &::before, &::after {
                content: '';
                position: absolute;
                right: 16px;
                height: 2px;
                width: 20px;
                background-color: $secondary;
                top: 50%;
                transition: transform .5s;
            }

            &.collapsed{
                &::before {
                    transform: rotateZ(180deg);
                }

                &::after {
                    transform: rotateZ(270deg);
                }
            }

            &:focus{
                box-shadow: none;
                outline: none;
            }
        }

        &--title {
            font-size: 24px;
            text-align: left;
        }
    }
}